import { addMonths } from "./DatesHelper";
import { Hypothesis } from "./Hypothesis";

export class MonthlyAnalysis {
    number: number;
    
    date: Date;

    assetValue: number;
    incomingEarnings: number;
    cumulativeEarnings: number;


    incomingTaxes: number;
    incomingCharges: number;
    incomingCreditCost: number;
    incomingInvestment: number;
    
    cumulativePaidTaxes: number;
    cumulativePaidCharges: number;
    cumulativePaidCreditCost: number;
    cumulativePaidInvestment: number;
    
    remainingDebt: number;
    remainingInterests: number;

    
    get totalCost(): number{
        return this.cumulativePaidTaxes + 
            this.cumulativePaidCharges + 
            this.cumulativePaidCreditCost + 
            this.cumulativePaidInvestment;
    }

    get totalInvested(): number{
        return this.cumulativePaidInvestment;
    }

    get totalEarned(): number{
        return this.cumulativeEarnings;
    }

    get sellingCost(): number{
        return (0.08 * this.assetValue) + 1200
    }

    get onSellResult(): number{
        return roundTo2(this.totalEarned + this.assetValue - this.totalCost - this.sellingCost - this.remainingDebt)
    }

    get notSellingResult(): number{
        return roundTo2(this.totalEarned - this.totalCost)
    }



    constructor(h: Hypothesis, previous?: MonthlyAnalysis) {
        
        if (previous) {
            this.date = addMonths(new Date(previous.date), 1);
            this.number = previous.number + 1;
            this.assetValue = previous.assetValue + getRevalorisation(h,this.number,previous.assetValue);
            this.cumulativePaidTaxes = previous.cumulativePaidTaxes + previous.incomingTaxes;
            this.cumulativePaidCharges = previous.cumulativePaidCharges + previous.incomingCharges;
            this.cumulativePaidCreditCost = roundTo2(previous.cumulativePaidCreditCost + previous.incomingCreditCost);
            this.cumulativePaidInvestment = roundTo2(previous.cumulativePaidInvestment + previous.incomingInvestment);
            this.cumulativeEarnings = previous.cumulativeEarnings + previous.incomingEarnings;
            this.remainingDebt = roundTo2(previous.remainingDebt -  getCreditMonthlyAmount(h.creditAmount,h.creditYearsDuration,this.number));
            this.remainingInterests = roundTo2(previous.remainingInterests - getCreditMonthlyAmount(h.creditInterests,h.creditYearsDuration,this.number));
            this.incomingCharges = getAnnualExpenses(h,this.date) + h.runMonthlyExpenses;
        }
        else {
            this.date = h.startDate;
            this.number = 0;
            this.assetValue = h.assetValue;
            this.cumulativePaidTaxes = h.assetTransactionCost;
            this.cumulativePaidCharges = 0;
            this.cumulativePaidCreditCost = h.creditGaranties + h.creditAgencyFees;
            this.cumulativePaidInvestment = h.assetRenovationCost + h.assetCost - h.creditAmount;
            this.cumulativeEarnings = 0;
            this.remainingDebt = h.creditAmount;
            this.remainingInterests = h.creditInterests;
            this.incomingCharges = h.runMonthlyExpenses;
        }

        this.incomingEarnings = h.runMonthlyLocationSaving + h.runMonthlyLocationEarnings;
        this.incomingTaxes = getTax(h,this.date);
        this.incomingCreditCost = roundTo2(getCreditMonthlyAmount(h.creditInterests,h.creditYearsDuration,this.number) + getCreditMonthlyAmount(h.creditInsurance,h.creditYearsDuration,this.number));
        this.incomingInvestment = getCreditMonthlyAmount(h.creditAmount,h.creditYearsDuration,this.number);
    }
}

function getRevalorisation(h: Hypothesis, monthNumber: number, currentValue: number): number {
    
    if ((monthNumber % 12) === 0 && monthNumber <= 15 * 12) {
        return Math.round(h.runAssetAnnualValorisation / 100 * currentValue);
    }

    return 0;
}

function getTax(h: Hypothesis, d: Date): number {
    if (d.getMonth() === 10)
        return h.runAnnualTax;
    return 0;
}

function getAnnualExpenses(h: Hypothesis, d: Date): number {
    if (d.getMonth() === h.startDate.getMonth())
        return h.runAnnualExpenses;
    return 0;
}

function roundTo2(n: number) : number{
    return Number(n.toFixed(2))
}

function getCreditMonthlyAmount(total: number, nbYears: number, monthNumber: number): number{
    if (monthNumber > nbYears * 12)
        return 0;
    return roundTo2(total / (12*nbYears)); 
}
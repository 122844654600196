import { getNextMonthStart } from "./DatesHelper"

export type Hypothesis = {
    assetCost: number,
    assetTransactionCost: number,
    assetRenovationCost: number,
    assetValue: number,
    runMonthlyExpenses: number,
    runAnnualExpenses: number,
    runAnnualTax: number,
    runMonthlyLocationSaving: number,
    runMonthlyLocationEarnings: number,
    runAssetAnnualValorisation: number,
    creditAmount: number,
    creditAgencyFees: number,
    creditInterests: number,
    creditInsurance: number,
    creditGaranties: number,
    creditYearsDuration: number,
    startDate: Date
}

export const defaultHypothesis: Hypothesis =
{
    assetCost: 225000,
    assetValue: 250000,
    runAssetAnnualValorisation: 2,
    runMonthlyLocationSaving: 940,
    runMonthlyLocationEarnings: 0,
    creditAgencyFees: 1600,
    assetTransactionCost: 18000,
    assetRenovationCost: 25000,
    runMonthlyExpenses: 380,
    runAnnualExpenses: 1000,
    runAnnualTax: 550,
    creditAmount: 227000,
    creditInterests: 33516,
    creditInsurance: 7332,
    creditGaranties: 3927,
    creditYearsDuration: 25,
    startDate: getNextMonthStart()
}

export const christelHypothesis: Hypothesis = {
    assetCost: 350000,
    assetValue: 400000,
    runAssetAnnualValorisation: 0,
    runMonthlyLocationSaving: 1500,
    runMonthlyLocationEarnings: 0,
    creditAgencyFees: 3000,
    assetTransactionCost: 38000,
    assetRenovationCost: 13000,
    runMonthlyExpenses: 225,
    runAnnualExpenses: 0,
    runAnnualTax: 900,
    creditAmount: 367000,
    creditInterests: 56000,
    creditInsurance: 18000,
    creditGaranties: 7000,
    creditYearsDuration: 25,
    startDate: new Date(2013,5,1)
}
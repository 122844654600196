export function addMonths(date: Date,months: number){
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d){
        date.setDate(0);
    }
    return date;
}

export function getNextMonthStart() : Date{
    let date : Date = new Date();
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    date = addMonths(date,1);
    return date;
}
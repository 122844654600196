import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Calculator from './components/Calculator'
import './App.css';
import { LegalNotes } from './components/LegalNotes';

function App() {
  return (
    <Router>
      <div className="app">
        <header>
          <div className="header-title">
            <h1>Rentabilité de votre investissement immobilier</h1>
          </div>
          <p>
            <b>Simulez vos investissements immobiliers et calculez-en la rentabilité avec cet outil.</b> Il est rapide à utiliser, gratuit, et vous guide pour que vous n'oubliez aucun paramètre ou aucun frais.
          </p>
        </header>
        <div className="container">
          <Routes>
            <Route path="/mentions-legales" element={<LegalNotes/>}/>
            <Route path="/" element={<Calculator/>}/>
          </Routes>
        </div>
        <footer>
          <div className="footer-links">
            <Link to="/">Accueil</Link>
            <Link to="/mentions-legales">Mentions légales</Link>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;

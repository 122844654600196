import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from "highcharts/highcharts-more";
import { Hypothesis } from '../Simulation/Hypothesis';
import { Colors } from '../Colors';

type InvestmentCompositionCardPros = {
    hypothesis: Hypothesis
}
type InvestmentCompositionCardState = {
}

export default class InvestmentCompositionCard extends React.Component<InvestmentCompositionCardPros, InvestmentCompositionCardState> {
    constructor(props: InvestmentCompositionCardPros) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        HighchartsMore(Highcharts);
        // Everything that should be paid when doing the transaction
        // e.g. everything but debts and insurances
        let paidWhenDoingTransaction: number = this.props.hypothesis.assetCost + 
            this.props.hypothesis.assetRenovationCost + 
            this.props.hypothesis.assetTransactionCost + 
            this.props.hypothesis.creditAgencyFees +
            this.props.hypothesis.creditGaranties;

        let totalDebt: number = this.props.hypothesis.creditAmount +
            this.props.hypothesis.creditInsurance +
            this.props.hypothesis.creditInterests;

        // Personal contribution calculated with credit amount instead of asking for it
        // because you'll create a credit covering everything that you should pay when doing the transaction
        // and that you can't pay with your own personal contribtion
        let personalContribution: number = paidWhenDoingTransaction - this.props.hypothesis.creditAmount;

        /*
        // You should personally finance everything that is not asset value to have good interests
        // as a bank could get your asset and sell it if you don't pay the credit
        let goalToPersonallyFinance: number = 
            this.props.hypothesis.assetTransactionCost + 
            this.props.hypothesis.assetRenovationCost + 
            this.props.hypothesis.creditAgencyFees +
            this.props.hypothesis.creditGaranties;

        // The minimum to personally finance
        let minimumToPersonallyFinance: number =  
            this.props.hypothesis.assetTransactionCost + 
            this.props.hypothesis.assetRenovationCost;

        // The cost to be paid to not have any credit
        let maximumToPersonallyFinance: number = 
            this.props.hypothesis.assetCost +
            this.props.hypothesis.assetRenovationCost +
            this.props.hypothesis.assetTransactionCost;
        */

        const otherOtherOptions: Highcharts.Options = {
            chart: {
                type: 'waterfall'
            },

            title: {
                text: ''
            },

            xAxis: {
                type: 'category'
            },

            yAxis: {
                title: {
                    text: ''
                }
            },

            credits: {
                enabled: false
            },

            legend: {
                enabled: false
            },

            tooltip: {
                pointFormat: '<b>{point.y:,.2f}</b> €'
            },

            series: [{
                type: 'waterfall',
                upColor: Colors.Up,
                color: Colors.Down,
                data: [
                    {
                        name: "Frais de transaction",
                        y: (this.props.hypothesis.assetTransactionCost)
                    },
                    {
                        name: 'Frais de rénovation',
                        y: (this.props.hypothesis.assetRenovationCost)
                    },
                    {
                        name: 'Frais de dossier',
                        y: (this.props.hypothesis.creditAgencyFees)
                    },
                    {
                        name: "Garantie bancaire",
                        y: (this.props.hypothesis.creditGaranties)
                    },
                    {
                        name: 'Objectif apport personnel',
                        isSum: true,
                        color: Colors.Result
                    }],
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return Highcharts.numberFormat(this.y ? this.y / 1000 : 0, 0, ',') + 'k';
                    },
                    style: {
                        fontWeight: 'bold'
                    }
                },
                pointPadding: 0
            }]

        };

        return (
            <div>
                <h3>Financement</h3>
                <div style={{ marginBottom: "20px" }}>
                    Vous financez votre investissement en apportant <span className="kpi">{personalContribution.toString()}€</span> <br />
                    et en vous endettant pour <span className="kpi">{totalDebt.toString()}€</span>
                </div>
                <div style={{ marginBottom: "20px" }}>
                    Afin d'optimiser le taux d'intérêt à la banque, <b>il est recommandé d'avoir un apport personnel finançant tous les frais hors prix d'achat du bien</b>.<br/><br/>
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <HighchartsReact highcharts={Highcharts} options={otherOtherOptions} />
                </div>
                <div style={{ marginBottom: "20px" }}>
                    Ainsi, la banque est assurée de récupérer le montant qu'elle vous a prêté si vous ne la remboursez pas, en saisissant votre bien. 
                </div>
            </div>
        )
    }
}
import React from 'react';
import './Separator.css';

export type SeparatorProps = {
}

export class Separator extends React.Component<SeparatorProps>{
    render() {
        return (
                <div className="separator">
                </div>         
            )
    }
}
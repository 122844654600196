import React from 'react';
import './Tag.css';

export type TagProps = {
    text: string
}

export class Tag extends React.Component<TagProps>{
    render() {
        return (
                <span className="tag">{this.props.text}</span>         
            )
    }
}
import { MonthlyAnalysis } from "./MonthlyAnalysis";
import { Hypothesis } from "./Hypothesis";

export function simulate(hypothesis: Hypothesis) : MonthlyAnalysis[]{

    let analysis: MonthlyAnalysis[] = [];

    let numberMonthsAnalysis = 12*Math.max(hypothesis.creditYearsDuration+5,30);

    let currentMonth = new MonthlyAnalysis(hypothesis)
    analysis.push(currentMonth);
    for (let i = 0; i<numberMonthsAnalysis;i++){
        currentMonth = new MonthlyAnalysis(hypothesis,currentMonth);
        analysis.push(currentMonth);
    }
    return analysis;
}
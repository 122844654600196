import React from 'react';
import Highcharts from 'highcharts/highstock';
import seriesLabel from 'highcharts/modules/series-label';
import accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
import { MonthlyAnalysis } from '../Simulation/MonthlyAnalysis'
import { Colors } from '../Colors';
import './InvestmentSimulationChart.css';
import { SimulationKPI } from './SimulationKPI';

seriesLabel(Highcharts);
accessibility(Highcharts);

const months = [
    'Janvier', 'Février', 'Mars', 'Avril',
    'Mai', 'Juin', 'Juillet', 'Août',
    'Septembre', 'Octobre', 'Novembre', 'Décembre'
];

Highcharts.setOptions({
    lang: {
        months: months,
        weekdays: [
            'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
            'Jeudi', 'Vendredi', 'Samedi'
        ]
    }
});

type InvestmentSimulationChartPros = {
    analysis: MonthlyAnalysis[],
    selling: boolean,
    detailedView: boolean
}
type InvestmentSimulationChartState = {
    selectedMonth: number
}

export default class InvestmentSimulationChart extends React.Component<InvestmentSimulationChartPros, InvestmentSimulationChartState> {
    constructor(props: InvestmentSimulationChartPros) {
        super(props);

        this.state = {
            selectedMonth: 0
        };
    }

    componentDidMount() {

    }

    setHoverData = (e: any) => {
        this.setState({ selectedMonth: e.target.index })
    }

    render() {
        const resultSelling = this.props.analysis.map((a: MonthlyAnalysis) => {
            return [a.date.getTime(), a.onSellResult];
        })
        const resultNotSelling = this.props.analysis.map((a: MonthlyAnalysis) => {
            return [a.date.getTime(), a.notSellingResult];
        });
        const resultSeries = [{
            name: "Sans revente",
            data: resultNotSelling,
            lineWidth: 3,
            color: Colors.Result2,
            type: 'spline'
        }, {
            name: "Avec revente",
            data: resultSelling,
            lineWidth: 3,
            color: Colors.Result,
            type: 'spline'
        }];

        let options = {
            chart: {
                zoomType: 'x',
                height: this.props.detailedView ? 300 : 300
            },
            xAxis: {
                type: 'datetime'
            },
            yAxis: {
                title: {
                    text: ""
                },
                labels: {
                    enabled: true
                }
            },
            legend: {
                enabled: false,
                align: 'right',
                verticalAlign: 'top'
            },
            title: {
                text: ""
            },
            time: {
                useUTC: false
            },
            tooltip: {
                crosshairs: {
                    dashStyle: 'dashed'
                },
                enabled: false,
                shared: true,
                valueSuffix: ' €'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    point: {
                        events: {
                            mouseOver: this.setHoverData.bind(this)
                        }
                    }
                }
            },
            series: resultSeries
        }

        return (
            
            <div className="simulationResult">
                <h3>Simulation</h3>
                <div className="card">
                    <div style={{ margin: "20px 0 20px 0" }}>
                    <span className="kpi">{months[this.props.analysis[this.state.selectedMonth].date.getMonth()]} {this.props.analysis[this.state.selectedMonth].date.getFullYear()}</span>, 
                    <span> le résultat à la revente </span> est de <span className="kpi" style={{ color: Colors.Result }}>{this.props.analysis[this.state.selectedMonth].onSellResult.toLocaleString()}€</span> 
                    <span> et le résultat sans revente </span>est de <span className="kpi" style={{ color: Colors.Result2 }}>{this.props.analysis[this.state.selectedMonth].notSellingResult.toLocaleString()}€</span>.
                    </div>
                    <div className="chartZone">
                        <HighchartsReact highcharts={Highcharts} options={options} />
                    </div>
                </div>

                <div className="card" style={{ margin: "20px 0 20px 0" }}>
                    <div className="kpiRow" style={{ margin: "20px 0 20px 0" }}>
                        <SimulationKPI
                            kpiValue={this.props.analysis[this.state.selectedMonth].cumulativeEarnings}
                            subValue={this.props.analysis[this.state.selectedMonth].incomingEarnings}
                            title="Revenus"
                            kpiColor={Colors.Up}
                            subTitle="Total de l'argent gagné"
                        />
                        <SimulationKPI
                            kpiValue={-this.props.analysis[this.state.selectedMonth].totalInvested}
                            subValue={-this.props.analysis[this.state.selectedMonth].incomingInvestment}
                            title="Investissement"
                            kpiColor={Colors.Down}
                            subTitle="Apport et remboursement de crédit"
                        />
                        <SimulationKPI
                            kpiValue={-this.props.analysis[this.state.selectedMonth].cumulativePaidCharges}
                            subValue={-this.props.analysis[this.state.selectedMonth].incomingCharges}
                            title="Charges payées"
                            kpiColor={Colors.Down}
                            subTitle="Frais de maintenance du bien"
                        />
                        <SimulationKPI
                            kpiValue={-this.props.analysis[this.state.selectedMonth].cumulativePaidTaxes}
                            subValue={-this.props.analysis[this.state.selectedMonth].incomingTaxes}
                            title="Impôts et taxes payées"
                            kpiColor={Colors.Down}
                            subTitle="Argent donné à l'état au moment de la transaction ou après"
                        />
                    </div>
                    <div className="kpiRow" style={{ margin: "0 0 20px 0" }}>
                        <SimulationKPI
                            kpiValue={-this.props.analysis[this.state.selectedMonth].cumulativePaidCreditCost}
                            subValue={-this.props.analysis[this.state.selectedMonth].incomingCreditCost}
                            title="Coût du crédit payé"
                            kpiColor={Colors.Down}
                            subTitle="Intérêts, assurances, caution, frais de courtage"
                        />
                        <SimulationKPI
                            kpiValue={this.props.analysis[this.state.selectedMonth].assetValue}
                            title="Valeur du bien"
                            kpiColor={Colors.Up}
                            subTitle="Uniquement en cas de revente. Valeur au moment de la revente."
                        />
                        <SimulationKPI
                            kpiValue={-this.props.analysis[this.state.selectedMonth].remainingDebt}
                            title="Dette à rembourser"
                            kpiColor={Colors.Down}
                            subTitle="Uniquement en cas de revente. Montant restant à rembourser."
                        />
                        <SimulationKPI
                            kpiValue={-this.props.analysis[this.state.selectedMonth].sellingCost}
                            title="Frais de revente"
                            kpiColor={Colors.Down}
                            subTitle="Uniquement en cas de revente. Frais d'agence (8% de la valeur du bien) et des frais de remboursement anticipés."
                        />
                    </div>
                </div>
            </div >
        )
    }
}
import React from 'react';
import {
    Link,
} from "react-router-dom";
import { Separator } from './Separator';

export type LegalNotesProps = {
}

export class LegalNotes extends React.Component<LegalNotesProps>{
    render() {
        return (
            <div>
                <Link to="/">Retour au calculateur</Link>
                <h2>Investissement</h2>
                <Separator />
                <p>Ce site est édité par Stéphane Magne et hébergé par <a href="https://azure.microsoft.com/fr-fr/" title="Microsoft Azure">Microsoft Azure</a> géographiquement localisé dans le centre d'hébergement de Microsoft <b>sur la région Europe de l'Ouest</b> depuis Août 2021. </p>
                <p>Tous droits réservés.</p>
                <h2>Données personnelles</h2>
                <Separator />
                <p>Votre adresse IP et la référence de la page visitée font partie des informations personnelles enregistrées sur le serveur qui héberge ce site.</p>
                <p>Aucune autre donnée personnelle n'est enregistrée</p>
                <p>Ces données peuvent éventuellement servir à diagnostiquer un problème, mais elles ne seront jamais transmises à des tiers et elles sont supprimées automatiquement.</p>
                <p>Pour toute demande liée aux données personnelles, nous vous demandons de <a href="mailto:simule-immobilier@outlook.fr">nous contacter par mail</a>.</p>
            </div>
        );
    }
}
import React from 'react';
import '../App.css';

export type NumberFieldProps = {
    name: string,
    width: number,
    step?: number,
    min?: number,
    max?: number,
    softMinMax?: boolean,
    value: number,
    onChange: (name: string, value: number) => void
}

export type NumberFieldState = {
    formattedValue: string
}


export class NumberField extends React.Component<NumberFieldProps, NumberFieldState>{
    constructor(props: NumberFieldProps) {
        super(props);

        this.state = {
            formattedValue: this.formatValue(props.value)
        };

        this.onChange = this.onChange.bind(this);
    }

    formatValue(v: number) {
        return v.toLocaleString();
    }

    onChange(event: React.FormEvent<HTMLInputElement>) {
        let sendEvent: boolean = true;
        const target = event.currentTarget;
        let value = Number(target.value.replace(/[^0-9^ ]/gi, ''));
        console.info('number', value);
        if (isNaN(value))
            value = 0;
        value = Math.round(value);
        if (this.props.min !== undefined && value < this.props.min) {
            if (this.props.softMinMax)
                sendEvent = false
            else
                value = this.props.min;
        }

        if (this.props.max !== undefined && value > this.props.max){
            if (this.props.softMinMax)
                sendEvent = false
            else
                value = this.props.max;
        }

        this.setState({ formattedValue: this.formatValue(value) });
        if (sendEvent)
            this.props.onChange(this.props.name, value);
    }

    render() {
        let widthValue = `${this.props.width}px`;
        return (
            <input
                type="tel"
                name={this.props.name}
                value={this.state.formattedValue}
                style={{ width: widthValue }}
                onChange={this.onChange}
            />
        );
    }
}
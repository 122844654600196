import React from 'react';
import { Colors } from '../Colors';
import './SimulationKPI.css';

export type SimulationKPIProps = {
    kpiValue: number
    subValue?: number
    title: string
    kpiColor: string
    subTitle?: string
}

export class SimulationKPI extends React.Component<SimulationKPIProps>{
    render() {
        return (
            <div className="kpiCard">
                <div className="kpi" style={{ color: this.props.kpiColor }}>{this.props.kpiValue.toLocaleString()}</div>
                {
                    this.props.subValue !== undefined && 
                    <div className="subkpi" style={{ color: this.props.subValue > 0 ? Colors.UpDarker : this.props.subValue === 0 ? Colors.Neutral : Colors.DownDarker }}>
                        {this.props.subValue > 0 && <span>+{this.props.subValue.toLocaleString()} à venir</span>}
                        {this.props.subValue === 0 && <span>Rien ce mois</span>}
                        {this.props.subValue <0 && <span>{this.props.subValue.toLocaleString()} à venir</span>}
                    </div>
                }
                <div className="kpiLegend">
                    <b>{this.props.title}</b>
                    <br/>
                    {
                        this.props.subTitle
                    }
                </div>
            </div>
        )
    }
}
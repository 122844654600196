import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from "highcharts/highcharts-more";
import { Hypothesis } from '../Simulation/Hypothesis';
import { Colors } from '../Colors';

type InitialInvestmentCardPros = {
    hypothesis: Hypothesis
}
type InitialInvestmentCardState = {
}

export default class InitialInvestmentCard extends React.Component<InitialInvestmentCardPros, InitialInvestmentCardState> {
    constructor(props: InitialInvestmentCardPros) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        HighchartsMore(Highcharts);
        let assetTotalCost: number = this.props.hypothesis.assetCost + this.props.hypothesis.assetRenovationCost + this.props.hypothesis.assetTransactionCost;
        let creditCost: number = this.props.hypothesis.creditGaranties + this.props.hypothesis.creditInsurance + this.props.hypothesis.creditInterests + this.props.hypothesis.creditAgencyFees;
        const options: Highcharts.Options = {
            chart: {
                type: 'waterfall'
            },

            title: {
                text: ''
            },

            xAxis: {
                type: 'category'
            },

            yAxis: {
                title: {
                    text: ''
                }
            },

            credits: {
                enabled: false
            },

            legend: {
                enabled: false
            },

            tooltip: {
                pointFormat: '<b>{point.y:,.2f}</b> €'
            },

            series: [{
                type: 'waterfall',
                upColor: Colors.Up,
                color: Colors.Down,
                data: [
                    {
                        name: "Valeur d'achat",
                        y: -(this.props.hypothesis.assetCost)
                    },
                    {
                        name: 'Frais de transaction',
                        y: -(this.props.hypothesis.assetTransactionCost)
                    },
                    {
                        name: 'Frais de rénovation',
                        y: -(this.props.hypothesis.assetRenovationCost)
                    },
                    {
                        name: "Coût d'emprunt",
                        y: -(creditCost)
                    },
                    {
                        name: 'Valeur du bien',
                        y: this.props.hypothesis.assetValue
                    },
                    {
                        name: '+/- value',
                        isSum: true,
                        color: Colors.Result
                    }],
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return Highcharts.numberFormat(this.y ? this.y / 1000 : 0, 0, ',') + 'k';
                    },
                    style: {
                        fontWeight: 'bold'
                    }
                },
                pointPadding: 0
            }]
        };
       
        let investmentCost: number = assetTotalCost + creditCost;
        return (
            <div>
                <h3>Investissement</h3>
                <div style={{ marginBottom: "20px" }}>
                    Vous investissez au total <span className="kpi" style={{ margin: "0 0 0 5px", color: Colors.Down }}>{investmentCost.toString()}€</span> <br />
                    et votre bien est estimé à <span className="kpi" style={{ margin: "0 0 0 5px", color: Colors.Up }}>{this.props.hypothesis.assetValue.toString()}€</span>
                </div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        )
    }
}
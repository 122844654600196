import React from 'react';
import '../App.css';
import { NumberField } from './NumberField';

export type MonthFieldProps = {
    name: string,
    value: Date,
    onChange: (name: string, date: Date) => void
}

export type MonthFieldState = {
    selectedMonth: number,
    selectedYear: number
}


export class MonthField extends React.Component<MonthFieldProps, MonthFieldState>{
    constructor(props: MonthFieldProps) {
        super(props);

        this.state = {
            selectedMonth: props.value.getMonth() + 1,
            selectedYear: props.value.getFullYear(),
        };

        this.onChange = this.onChange.bind(this);
    }

    formatValue(v: number) {
        return v.toLocaleString();
    }

    onChange(name: string, value: number) {
        if (name === "startMonth"){
            this.props.onChange(this.props.name,new Date(this.state.selectedYear,value - 1,1));
        }
        if (name === "startYear"){
            this.props.onChange(this.props.name,new Date(value,this.state.selectedMonth - 1,1));
        }
    }

    render() {
        return (
            <div className="dateSelector">
                <span> Mois de démarrage </span>
                <NumberField name="startMonth"
                    width={30}
                    step={1}
                    min={1}
                    max={12}
                    softMinMax={true}
                    value={this.state.selectedMonth}
                    onChange={this.onChange} />
                <span> / </span>
                <NumberField name="startYear"
                    width={50}
                    step={1}
                    min={1950}
                    max={2050}
                    softMinMax={true}
                    value={this.state.selectedYear}
                    onChange={this.onChange} />
            </div>

        );
    }
}
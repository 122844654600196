import React from 'react';
import '../App.css';
import { Separator } from './Separator'
import { Tag } from './Tag'
import { MonthlyAnalysis } from '../Simulation/MonthlyAnalysis'
import { defaultHypothesis, Hypothesis } from '../Simulation/Hypothesis'
import { simulate } from '../Simulation/Simulator'
import InvestmentSimulationChart from './InvestmentSimulationChart'
import InitialInvestmentCard from './InitialInvestmentCard';
import InvestmentCompositionCard from './InvestmentCompositionCard';
import { NumberField } from './NumberField';
import { MonthField } from './MonthField';

type CalculatorProps = {}
type CalculatorState = {
    hypothesis: Hypothesis,
    analysis: MonthlyAnalysis[],
    calculationDone: boolean
}

export default class Calculator extends React.Component<CalculatorProps, CalculatorState> {
    constructor(props: CalculatorProps) {
        super(props);

        this.state = {
            hypothesis: defaultHypothesis,
            analysis: [],
            calculationDone: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.startCalculation = this.startCalculation.bind(this);
        this.setState = this.setState.bind(this);
    }

    componentDidMount() {
        this.startCalculation();
    }

    handleInputChange(name: string, value: number | Date) {
        this.setState((current) => {
            let hypothesis: Hypothesis = {
                ...current.hypothesis,
                [name]: value
            }
            let analysis: MonthlyAnalysis[] = simulate(hypothesis);

            let newState: CalculatorState =
            {
                ...current,
                hypothesis: hypothesis,
                analysis: analysis,
                calculationDone: true
            }
            return newState;
        });
    }

    startCalculation() {
        let savedHypothesis = this.state.hypothesis;
        let analysis: MonthlyAnalysis[] = simulate(savedHypothesis);

        this.setState({
            calculationDone: true,
            analysis: analysis
        });

    }

    render() {
        return (
            <div>
                <h2>Investissement</h2>
                <Separator />
                <div className="row">
                    <div className="col">
                        <div style={{ margin: "0 0 20px 0" }}>
                            <h3>Coût d'acquisition du bien</h3>
                            <div className="settings-form">
                                <div className="entry">
                                    <span>Vous achetez le bien</span>
                                    <NumberField name="assetCost"
                                        width={85}
                                        step={1}
                                        min={0}
                                        value={this.state.hypothesis.assetCost}
                                        onChange={this.handleInputChange} />
                                    <span> €</span>
                                </div>
                                <div className="entry">
                                    <span>Vous payez </span>
                                    <NumberField name="assetTransactionCost"
                                        width={60}
                                        step={1}
                                        min={0}
                                        value={this.state.hypothesis.assetTransactionCost}
                                        onChange={this.handleInputChange} />
                                    <span> € en frais de transaction.</span>
                                    <div className="astuce">
                                        <div>
                                            Pour un logement ancien, compter environ <Tag text="8%" /> du prix de vente en frais.
                                        </div>
                                        <div className="links">
                                            <ul>
                                                <li><a href="https://www.pap.fr/acheteur/frais-notaire/les-frais-de-notaire/a1307/les-frais-de-notaire-dans-lancien" target="_blank" title="Les frais de notaire dans l'ancien" rel="noreferrer">Les frais de notaire dans l'ancien</a></li>
                                                <li><a href="https://www.service-public.fr/particuliers/vosdroits/R16181" target="_blank" title="Calculer le montant des frais d'acquisition d'un bien immobilier" rel="noreferrer">Calculer le montant des frais d'acquisition d'un bien immobilier</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="entry">
                                    <span>Au moment de l'achat, vous faites </span>
                                    <NumberField name="assetRenovationCost"
                                        width={60}
                                        step={1}
                                        min={0}
                                        value={this.state.hypothesis.assetRenovationCost}
                                        onChange={this.handleInputChange} />
                                    <span> € de travaux.</span>
                                </div>
                                <div className="entry">
                                    <span>Grâce aux travaux et aux bonnes affaires que vous savez faire, vous estimez que votre bien vaut en réalité </span>
                                    <NumberField name="assetValue"
                                        width={85}
                                        step={1}
                                        min={0}
                                        value={this.state.hypothesis.assetValue}
                                        onChange={this.handleInputChange} />
                                    <span> €.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div style={{ margin: "0 0 20px 0" }}>
                            <h3>Prêt</h3>
                            <div className="settings-form">
                                <div className="entry">
                                    <span>Vous empruntez </span>
                                    <NumberField name="creditAmount"
                                        width={85}
                                        step={1}
                                        min={0}
                                        value={this.state.hypothesis.creditAmount}
                                        onChange={this.handleInputChange} />
                                    <span> € hors intérêts et autres frais sur </span>
                                    <NumberField name="creditYearsDuration"
                                        width={40}
                                        step={1}
                                        min={1}
                                        max={75}
                                        value={this.state.hypothesis.creditYearsDuration}
                                        onChange={this.handleInputChange} />
                                    <span> ans</span>
                                </div>
                                <div className="entry">
                                    <span>Au montant de l'emprunt s'ajoutent </span>
                                    <NumberField name="creditAgencyFees"
                                        width={60}
                                        step={1}
                                        min={0}
                                        value={this.state.hypothesis.creditAgencyFees}
                                        onChange={this.handleInputChange} />
                                    <span> € de frais d'agence ou de courtage, </span>
                                    <NumberField name="creditInterests"
                                        width={60}
                                        step={1}
                                        min={0}
                                        value={this.state.hypothesis.creditInterests}
                                        onChange={this.handleInputChange} />
                                    <span> € d'intérêts, </span>
                                    <NumberField name="creditInsurance"
                                        width={60}
                                        step={1}
                                        min={0}
                                        value={this.state.hypothesis.creditInsurance}
                                        onChange={this.handleInputChange} />
                                    <span> € d'assurance associée au prêt, et enfin </span>
                                    <NumberField name="creditGaranties"
                                        width={60}
                                        step={1}
                                        min={0}
                                        value={this.state.hypothesis.creditGaranties}
                                        onChange={this.handleInputChange} />
                                    <span> € d'hypothèque, caution crédit immo, ... </span>
                                    <div className="astuce">
                                        <div>
                                            Pour obtenir des échéanciers et détails :
                                        </div>
                                        <div className="links">
                                            <ul>
                                                <li><a href="https://www.service-public.fr/particuliers/vosdroits/R2924" target="_blank" title="Faire une simulation de l'échéancier et du TAEG d'un crédit immobilier (Simulateur)" rel="noreferrer">Faire une simulation de l'échéancier et du TAEG d'un crédit immobilier</a></li>
                                                <li><a href="https://www.creditlogement.fr/simulateur" target="_blank" title="Simulateur du coût de la garantie de votre prêt immobilier" rel="noreferrer">Simulateur du coût de la garantie de votre prêt immobilier</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.calculationDone && <div className="row">
                        <div className="col">
                            <div className="card" style={{ margin: "20px 0 20px 0" }}>
                                <InitialInvestmentCard hypothesis={this.state.hypothesis} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="card" style={{ margin: "20px 0 20px 0" }}>
                                <InvestmentCompositionCard hypothesis={this.state.hypothesis} />
                            </div>
                        </div>
                    </div>
                }
                <h2>Rentabilité</h2>
                <Separator />
                <p>
                    Contrairement aux idées reçues, un investissement immobilier n'est pas forcément rentable. Même s'il est vrai qu'on peut considérer la location comme de l'argent jeté par les fenêtres, l'acquisition d'un bien immobilier aussi a un coût : frais de transaction, intérêts d'emprunt, assurances, ...</p>
                <p>
                    La rentabilité d'un bien immobilier dépend de l'évolution du prix du bien, des charges et coûts associés, et des montants qu'il vous fait économiser ou gagner. Etant un investissement dans le temps, <b>le moment où vous revendez votre bien pour solder l'investissement compte beaucoup.</b>
                </p>
                <div className="row">
                    <div className="col">
                        <div style={{ margin: "0 0 20px 0" }}>
                            <h3>Charges</h3>
                            <div className="settings-form">
                                <div className="entry">
                                    <span>Vous payez mensuellement </span>
                                    <NumberField name="runMonthlyExpenses"
                                        width={40}
                                        step={1}
                                        min={0}
                                        value={this.state.hypothesis.runMonthlyExpenses}
                                        onChange={this.handleInputChange} />
                                    <span> € de charges de copropriété.</span>
                                </div>

                                <div className="entry">
                                    <span>En tant que propriétaire, vous prévoyez </span>
                                    <NumberField name="runAnnualExpenses"
                                        width={50}
                                        step={1}
                                        min={0}
                                        value={this.state.hypothesis.runAnnualExpenses}
                                        onChange={this.handleInputChange} />
                                    <span> € par an pour conserver le bien en bon état.<b> Ne comptez pas les frais que vous engageriez si vous étiez locataire.</b></span>
                                </div>

                                <div className="entry">
                                    <span>Le montant (annuel) de la taxe foncière est de </span>
                                    <NumberField name="runAnnualTax"
                                        width={40}
                                        step={1}
                                        min={0}
                                        value={this.state.hypothesis.runAnnualTax}
                                        onChange={this.handleInputChange} />
                                    <span> €.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div style={{ margin: "0 0 20px 0" }}>
                            <h3>Revenus</h3>
                            <div className="settings-form">
                                <div className="entry">
                                    <span>Grâce à cet achat, vous économiserez en frais de location </span>
                                    <NumberField name="runMonthlyLocationSaving"
                                        width={40}
                                        step={1}
                                        min={0}
                                        value={this.state.hypothesis.runMonthlyLocationSaving}
                                        onChange={this.handleInputChange} />
                                    <span> € par mois (car vous y habitez).</span>
                                </div>
                                <div className="entry">
                                    <span>En louant le bien, vous comptez gagner (après impôts et charges locatives) </span>
                                    <NumberField name="runMonthlyLocationEarnings"
                                        width={40}
                                        step={1}
                                        min={0}
                                        value={this.state.hypothesis.runMonthlyLocationEarnings}
                                        onChange={this.handleInputChange} />
                                    <span> € par mois.</span>
                                </div>
                                <div className="entry">
                                    <span>Vous pensez que le bien devrait gagner </span>
                                    <NumberField name="runAssetAnnualValorisation"
                                        width={30}
                                        step={1}
                                        min={0}
                                        max={100}
                                        value={this.state.hypothesis.runAssetAnnualValorisation}
                                        onChange={this.handleInputChange} />
                                    <span> % de valeur par an.</span>
                                    <div className="astuce">
                                        <div>
                                            <b>Adapter au marché et à l'emplacement. Il est conseillé d'être conservateur ! </b> Pour information, les prix des appartements anciens ont augmenté de <Tag text="4%" /> en 2019.
                                            <Tag text="4,7%" /> pour l'Ile-de-France et <Tag text="3,5%" /> en province.<br /><br />
                                            Dans la simulation, nous n'augmentons la valeur du bien que sur les 15 premières années.
                                        </div>
                                        <div className="links">
                                            <ul>
                                                <li><a href="https://www.notaires.fr/fr/immobilier-fiscalité/prix-et-tendances-de-limmobilier/analyse-du-marché-immobilier" target="_blank" rel="noreferrer" title="Marché immobilier : tendance et évolution des prix de l'immobilier">Marché immobilier : tendance et évolution des prix de l'immobilier</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.calculationDone && this.state.analysis && this.state.analysis.length > 0 && <div style={{ margin: "40px 0 0 0" }}>
                        <MonthField name="startDate" value={this.state.hypothesis.startDate} onChange={this.handleInputChange} />
                        <InvestmentSimulationChart detailedView={false} analysis={this.state.analysis} selling={false} />
                    </div>
                }
            </div>
        )
    }
}